// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-bg-color: #E6E6FA; /* Light Lavender */
  --secondary-bg-color: #ADD8E6; /* Pale Blue */
  --tertiary-bg-color: #98FF98; /* Light Mint */
  --primary-fg-color: #2F4F4F; /* Dark Slate Gray */
}
body {
  margin: 0;
  font-family: 'Georgia', serif;
  background-color: var(--primary-bg-color);
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App {
  text-align: center;
  padding: 20px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  height: 100%;
  overflow-y: auto;
  border-radius: 5px;
  background-color: var(--secondary-bg-color);
}

h1 {
  margin-top: 0;
  font-size: 2em;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B,EAAE,mBAAmB;EAChD,6BAA6B,EAAE,cAAc;EAC7C,4BAA4B,EAAE,eAAe;EAC7C,2BAA2B,EAAE,oBAAoB;AACnD;AACA;EACE,SAAS;EACT,6BAA6B;EAC7B,yCAAyC;EACzC,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,6CAA6C;EAC7C,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[":root {\n  --primary-bg-color: #E6E6FA; /* Light Lavender */\n  --secondary-bg-color: #ADD8E6; /* Pale Blue */\n  --tertiary-bg-color: #98FF98; /* Light Mint */\n  --primary-fg-color: #2F4F4F; /* Dark Slate Gray */\n}\nbody {\n  margin: 0;\n  font-family: 'Georgia', serif;\n  background-color: var(--primary-bg-color);\n  color: #333;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.App {\n  text-align: center;\n  padding: 20px;\n  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);\n  height: 100%;\n  overflow-y: auto;\n  border-radius: 5px;\n  background-color: var(--secondary-bg-color);\n}\n\nh1 {\n  margin-top: 0;\n  font-size: 2em;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
